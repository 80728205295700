import React from "react";
import './style.css';

const LeftBar_Item = ({ item, user, clickedItemId, status = "online", naoLidas = 0, handlerClickedItem, play, setNaoLidas }) => {
    let letras = "";
    const palavras = String(item.nomecliente).split(" ");
    for (let i = 0; i < palavras.length; i++) {
        letras += palavras[i][0];
    }
    letras = letras.substring(0, 2).toUpperCase();

    let background;
    var bolinha = "chat-user-img align-self-center me-2 ms-0 " + status;
    if (status === "online") {
        background = "avatar-title rounded-circle text-uppercase text-white bg-alert";
    }
    else if (status === "offline") {
        background = "avatar-title rounded-circle text-uppercase text-white bg-info";
    }
    else if (status === "away") {
        background = "avatar-title rounded-circle text-uppercase text-white bg-danger";
    }

    const verificaCount = () => {
        if (item.tecnico_id === user.id) {
            naoLidas && setNaoLidas(0); // Reseta se for o operador
        }
    };

    var letra = '';
    var disabled_li = '';

    if (item.posicao > 1) {
        disabled_li = 'list-group-item-dark';
    }

    if (item.nota === 5) {
        letra = 'text-success';
    } else if (item.nota === 4) {
        letra = 'text-warning';
    } else if (item.nota >= 1 && item.nota <= 3) {
        letra = 'text-danger';
    }

    const isActive = item.id === clickedItemId; // Verifica se este item é o clicado
    const itemClass = `list-group-item ${isActive ? 'ativo' : ''} ${disabled_li} ${naoLidas > 0 ? 'bg-warninga' : ''}`;

    return (
        <li className={itemClass}
            id={item.id}
            onClick={() => {
                if (item.posicao === 1 || item.status !== "Em Aberto") {
                    handlerClickedItem(item);
                    verificaCount();
                }
            }}>
            <a>
                <div className='d-flex align-items-center'>
                    <div className={bolinha}>
                        <div className="avatar-xs">
                            <span className={background}>
                                <span className="username"> {letras}</span>
                                <span className="user-status"></span>
                            </span>
                        </div>
                    </div>
                    <div title={item.nomecliente} className="overflow-hidden" style={{ width: "100vh" }}>
                        <p className={`text-truncate mb-0 ${letra}`}>{item.nomecliente}</p>
                        <div className="row container-flex">
                            <div className="col-md-9">
                                <p
                                    className="text-truncate mb-0"
                                    style={{
                                        fontSize: 10,
                                        color: item.tecnicon === "ANDREIA GODKE" || item.tecnicon === "PRIMEIRO ATENDIMENTO" ? "red" : "inherit"
                                    }}
                                >
                                    {item.operador} - {item.tecnicon}
                                </p>
                            </div>
                            <div className="col-md-3" style={{ textAlign: "right" }}>
                                <p className="mb-0" style={{ fontSize: 10 }}>{item.dataabertura}&nbsp;&nbsp;</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12" style={{ textAlign: "left" }}>
                                <p className="mb-0" style={{ fontSize: 10 }}>{item.categoria1}</p>
                            </div>
                        </div>
                    </div>
                    <div className="ms-auto">
                        <span className="badge badge-soft-danger rounded p-1">
                            {naoLidas > 0 && naoLidas}
                        </span>
                    </div>
                </div>
            </a>
        </li>
    );
}

export default LeftBar_Item;
